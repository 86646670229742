define("ember-modal-dialog/templates/components/tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.hasOverlay}}
    <EmberWormhole @to={{this.destinationElementId}}>
      <EmberModalDialog::Overlay
        class={{this.overlayClassNamesString}}
        @onClickOverlay={{this.onClickOverlay}}
      />
    </EmberWormhole>
  {{/if}}
  <EmberTether
    class={{this.containerClassNamesString}}
    @target={{this.tetherTarget}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @classPrefix={{this.tetherClassPrefix}}
    @offset={{this.offset}}
    @targetOffset={{this.targetOffset}}
    @constraints={{this.constraints}}
    ...attributes
  >
    {{yield}}
  </EmberTether>
  */
  {
    "id": "R8M5eqJ/",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"hasOverlay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-wormhole\",[],[[\"@to\"],[[32,0,[\"destinationElementId\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ember-modal-dialog/overlay\",[[16,0,[32,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[32,0,[\"onClickOverlay\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"ember-tether\",[[16,0,[32,0,[\"containerClassNamesString\"]]],[17,1]],[[\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[32,0,[\"tetherTarget\"]],[32,0,[\"attachment\"]],[32,0,[\"targetAttachment\"]],[32,0,[\"targetModifier\"]],[32,0,[\"tetherClassPrefix\"]],[32,0,[\"offset\"]],[32,0,[\"targetOffset\"]],[32,0,[\"constraints\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs"
    }
  });
  _exports.default = _default;
});