define("ember-modal-dialog/templates/components/overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div
    class="emd-debug"
    tabindex='-1'
    data-emd-overlay
    {{did-insert this.didInsert}}
    {{will-destroy this.willDestroyNode}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "CQge8mWl",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"emd-debug\"],[24,\"tabindex\",\"-1\"],[24,\"data-emd-overlay\",\"\"],[17,1],[4,[38,0],[[32,0,[\"didInsert\"]]],null],[4,[38,1],[[32,0,[\"willDestroyNode\"]]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/overlay.hbs"
    }
  });
  _exports.default = _default;
});