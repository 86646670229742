define("@ember-data/model/index", ["exports", "@ember-data/model/-private"], function (_exports, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function get() {
      return _private.attr;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function get() {
      return _private.belongsTo;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _private.Model;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function get() {
      return _private.hasMany;
    }
  });
});