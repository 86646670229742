define("ember-attacher/templates/components/attach-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xkL1M6Ep",
    "block": "{\"symbols\":[\"emberPopper\",\"&default\"],\"statements\":[[1,[30,[36,5],[[32,0,[\"_parentFinder\"]]],null]],[6,[37,2],[[30,[36,8],[[32,0,[\"_currentTarget\"]],[30,[36,7],[[30,[36,6],[[32,0,[\"lazyRender\"]]],null],[32,0,[\"_mustRender\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[32,0,[\"ariaRole\"]],\"ember-attacher\",false,[32,0,[\"id\"]],[32,0,[\"_modifiers\"]],[32,0,[\"placement\"]],[32,0,[\"popperContainer\"]],[32,0,[\"_currentTarget\"]],[30,[36,0],[[32,0],\"_registerAPI\"],null],[32,0,[\"renderInPlace\"]]]],[[\"default\"],[{\"statements\":[[10,\"div\"],[15,0,[31,[[32,0,[\"_class\"]]]]],[15,5,[32,0,[\"_style\"]]],[12],[2,\"\\n      \"],[18,2,[[30,[36,1],null,[[\"emberPopper\",\"hide\"],[[32,1],[30,[36,0],[[32,0],\"hide\"],null]]]]]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"arrow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,\"x-arrow\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,3],[[32,0,[\"animation\"]],\"fill\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,\"x-circle\",\"\"],[15,5,[32,0,[\"_circleTransitionDuration\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"if\",\"eq\",\"ember-popper\",\"unbound\",\"not\",\"or\",\"and\"]}",
    "meta": {
      "moduleName": "ember-attacher/templates/components/attach-popover.hbs"
    }
  });
  _exports.default = _default;
});