define("ember-ajax/-private/utils/parse-response-headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CRLF = void 0;
  _exports.default = parseResponseHeaders;
  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var CRLF = "\r\n";
  _exports.CRLF = CRLF;
  function parseResponseHeaders(headersString) {
    var headers = {};
    if (!headersString) {
      return headers;
    }
    return headersString.split(CRLF).reduce(function (hash, header) {
      var _header$split = header.split(':'),
        _header$split2 = _toArray(_header$split),
        field = _header$split2[0],
        value = _header$split2.slice(1);
      field = field.trim();
      var valueString = value.join(':').trim();
      if (valueString) {
        hash[field] = valueString;
      }
      return hash;
    }, headers);
  }
});