define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div
    class={{concat
      this.containerClassNamesString
      ' '
      this.attachmentClass
      ' '
      this.containerClass
    }}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "3ek1FE4k",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,0],[[32,0,[\"containerClassNamesString\"]],\" \",[32,0,[\"attachmentClass\"]],\" \",[32,0,[\"containerClass\"]]],null]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs"
    }
  });
  _exports.default = _default;
});