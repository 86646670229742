define("ember-power-select/components/power-select/search-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="ember-power-select-options" role="listbox" ...attributes>
    <li class="ember-power-select-option ember-power-select-option--search-message" role="option" aria-selected={{false}}>
      {{@searchMessage}}
    </li>
  </ul>
  */
  {
    "id": "YZDMC+UA",
    "block": "{\"symbols\":[\"&attrs\",\"@searchMessage\"],\"statements\":[[11,\"ul\"],[24,0,\"ember-power-select-options\"],[24,\"role\",\"listbox\"],[17,1],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--search-message\"],[14,\"role\",\"option\"],[15,\"aria-selected\",false],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/search-message.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});