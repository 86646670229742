define("ember-cli-showdown/templates/components/markdown-to-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rX8loKB2",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"html\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-cli-showdown/templates/components/markdown-to-html.hbs"
    }
  });
  _exports.default = _default;
});