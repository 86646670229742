define("ember-launch-darkly/index", ["exports", "ember-launch-darkly/-sdk"], function (_exports, _sdk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "identify", {
    enumerable: true,
    get: function get() {
      return _sdk.identify;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _sdk.initialize;
    }
  });
  Object.defineProperty(_exports, "variation", {
    enumerable: true,
    get: function get() {
      return _sdk.variation;
    }
  });
});