define("ember-modal-dialog/utils/config-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clickHandlerDelay = clickHandlerDelay;
  _exports.getDestinationElementIdFromConfig = getDestinationElementIdFromConfig;
  _exports.isIOS = void 0;
  function getDestinationElementIdFromConfig(config) {
    // if (config.environment === 'test') {
    //   return 'ember-testing';
    // }
    var modalContainerId = config['ember-modal-dialog'] && config['ember-modal-dialog'].modalRootElementId;
    modalContainerId = modalContainerId || 'modal-overlays';
    return modalContainerId;
  }
  var isIOS = (globalThis.navigator || false) && /iPad|iPhone|iPod/.test(navigator.userAgent);
  _exports.isIOS = isIOS;
  function clickHandlerDelay(component) {
    var ENV = Ember.getOwner(component).resolveRegistration('config:environment');
    if (ENV.environment === 'test') {
      return 0;
    }
    return 300;
  }
});